// Generated by Framer (c01e615)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sWsCJIz9Y"];

const variantClassNames = {sWsCJIz9Y: "framer-v-pzg7e5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, text, width, ...props}) => { return {...props, wLFP1gh2H: text ?? props.wLFP1gh2H ?? "Tailored solutions for a divorce that fits your life"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wLFP1gh2H, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "sWsCJIz9Y", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Tvj6D", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-pzg7e5", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sWsCJIz9Y"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtcmVndWxhcg==", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "28px", "--framer-letter-spacing": "-0.4px", "--framer-line-height": "1.4em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-1of0zx5)"}}>Tailored solutions for a divorce that fits your life</motion.h2></React.Fragment>} className={"framer-danahg"} data-framer-name={"A one-stop-shop for virtually any divorce scenario"} fonts={["GF;Inter Tight-regular"]} layoutDependency={layoutDependency} layoutId={"ABSpshfAB"} style={{"--extracted-1of0zx5": "rgb(28, 48, 84)", "--framer-paragraph-spacing": "0px"}} text={wLFP1gh2H} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Tvj6D [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Tvj6D .framer-1bn1bmq { display: block; }", ".framer-Tvj6D .framer-pzg7e5 { height: 79px; overflow: hidden; position: relative; width: 342px; }", ".framer-Tvj6D .framer-danahg { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 49%; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 79
 * @framerIntrinsicWidth 342
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wLFP1gh2H":"text"}
 */
const FramerAdYAj9KaB: React.ComponentType<Props> = withCSS(Component, css, "framer-Tvj6D") as typeof Component;
export default FramerAdYAj9KaB;

FramerAdYAj9KaB.displayName = "Content - Header / Subheader";

FramerAdYAj9KaB.defaultProps = {height: 79, width: 342};

addPropertyControls(FramerAdYAj9KaB, {wLFP1gh2H: {defaultValue: "Tailored solutions for a divorce that fits your life", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerAdYAj9KaB, [{family: "Inter Tight", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/AdYAj9KaB:default", url: "https://fonts.gstatic.com/s/intertight/v3/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-qXCRToK8EPg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/intertight/v3/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-qXCRToK8EPg.ttf", weight: "400"}])